
/*
label {
  display: block;
  margin-top: 10px;
}
*/
.login-container {
  height: 40%;
}
.card-container.card {
  
  max-width: 350px !important;
  padding: 40px 40px;
  top:30%;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  
  display: block;
}

.container {
  max-width: 80%;
  margin: 0 auto;
}

.section {
  padding: 50px 0;
}

.tools-button {
  width: 200px;
  height: 100px;
  margin: 20px;
}


.tool-card {
  display: block;
  margin-bottom: 30px;
  border: solid 1px grey;
  min-height: 300px;
}

.section-choices {
  margin: 0 20px;
  display: grid;
  
  grid-column-gap: 50px;
  grid-template-columns: 1fr 1fr;
  
}

.tool-card-wrapper-inner {
  margin: 0 20px;
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 1fr 1fr;
}

.sidebar {
  height: 100vh;
  position: sticky;
  top: 0;
}

.App {
  display: flex;
}
#newtable input {
  font-size:15px;
}
.column-search-inp1::placeholder {
  color: rgb(175, 175, 175);
}

.global-filter {
  align-items: center;
  display: flex;
  justify-content: center;
}
.global-search-p1 {
  margin-top:15px;
  margin-right: 20px;
  align-self: center;
}
.global-search-inp1 {

}
.display-table {
  overflow: auto;
}
.tool-card-wrapper-array {
  margin-top: 100px;
}
.refresh-button {
  margin-left: 30px;
  border-color: #2e2e2e;
  background-color:#2E2E2E;
}
.refresh-button:hover {
  border-color: #2e2e2e;
  background-color: grey;
}
.tool-card-header {
  font-size: 20px;
  color: white;
  background-color: #2e2e2e;
  padding: 10px 10px 1px 10px;
  
  text-align: center;
}

.create-chart-button {
  height: 50px;
  bottom: 0px;
  margin-top: 45px;
}

form {
  display: inline-block;
}

.form-inline {  
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline label {
  margin: 5px 10px 5px 0;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  width: 650px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.button-section {
  margin: 3%;
  
}
.button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: royalblue;
}

.remove {
  background-color: rgb(192, 53, 53);
}
.remove:hover {
  background-color: rgb(187, 43, 43);
}
.dynamic-api-form {
  margin-top: 50px;
}

.display-table table {
  width: 100%;
}
.display-table {
  max-height: 800px;
  border-bottom: solid 1px lightgrey;
}
.noPointerEvent {
  pointer-events: none;
}
.roleCheckBox {
  display: inline-block;
}
.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  
}
.data-simplebar {
  position: fixed;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .sidebar {
      width: 100%;
      max-width: 260px;
  }
}
.bg-primary {
  background-color: #262b40!important;
}
.text-white {
  color: #fff!important;
}
.text-white, .text-white:hover {
  color: #fff!important;
}
.sidebar .nav-item .nav-link {
  color: #eaedf2;
  border: .0625rem solid #262b40;
}
.sidebar .nav-link {
    font-size: 1rem;
    vertical-align: middle;
    padding: .55rem .75rem;
    border-radius: .5rem;
}
.nav {
  line-height: 1.6;
}
.nav-link, .nav-link.active, .nav-link:hover {
  color: #1f2937;
}
.nav-link {
  display: block;
  padding: .5rem 1rem;
  margin: .1rem 0rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.nav-link:hover {
  background-color: #2e3650;

}
.sidebar .nav-item.active > .nav-link {
  color: #eaedf2;
  background-color: #2e3650;
  border: .0625rem solid #4c5680;
}
.sidebar .nav-link .sidebar-icon.svg-icon {
  margin-right: 14px;
}
.sidebar .nav-link .sidebar-icon {
  margin-right: .5rem;
  color: #fff;
}

.bg-secondary {
  background-color: #61dafb!important;
}
.accordion-button {
  background-color:transparent;
}
.rounded-pill {
  border-radius: 2rem!important;
}
.badge {
  display: inline-block;
  padding: .1rem .4rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .5rem;
}
.sidebar .badge, .sidebar .link-arrow, .sidebar .notification-count, .sidebar .sidebar-text {
  opacity: 1;
  transition: opacity .3s;
}
.badge-md {
  padding: .25rem .4rem;
}
.badge {
  font-size: .75em;
  font-weight: 700;
}
.text-primary, .text-primary:hover {
  color: #262b40!important;
}

.accordion-item {
  background-color: #262b40;
  border: .0625rem solid #262b40;
}

.sidebar-accordion-icon {
  
  font-size: .875rem;
  font-weight: 900;
  height: 1.25rem;
  width: .5rem;
}

.sidebar-inner .accordion-button:not(.collapsed)::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: all .2s ease;
}
.sidebar-inner .accordion-button::after {
  background-image: none;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-size: .875rem;
  font-weight: 900;
  height: 1.25rem;
  width: .5rem;
}
.accordion-button::after {
  flex-shrink: 0;
  margin-left: auto;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #262b40;
  box-shadow: none;
}

/*
.accordion-button {
  text-align: left;
}
.accordion-button .btn-check:focus + .btn, .btn:focus {
      outline: 0;
      box-shadow: none;
  }
.accordion-button:hover{
      background-color: green;
  }

.accordion-flush .accordion-button {
      background-color: transparent;
}
.accordion-button :not(.collapsed) {
          color: green;
  }
.accordion-button :focus {
          border-color: gray;
          
      }
.accordion-collapse {
      border: 0;
  }

*/